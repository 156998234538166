export interface LoginUser {
  username: string;
  password: string;
}

export interface User {
  userId: string;
  setIds: Array<string>;
  roles: Array<string>;
  active: boolean;
  username: string;
  password: string;
}

export interface CreateUser {
  username: string;
  password: string;
  active: boolean;
  roles: Array<Role>;
}

export enum Role {
  User = "user",
  Uploader = "uploader",
  Admin = "admin",
}
