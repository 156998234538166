import { useEffect, useState } from 'react';
import { fetchWithReauth } from '../api/auth';
import { createExercise } from '../api/exercise';
import { uploadFile } from '../api/file';
import { Exercise } from '../models/exercise';
import { EditExercise } from './EditExercise';
import { Set } from '../models/set';
import defaultImage from '../assets/logo192.png';

interface CreateExerciseProps {
  fileCache: Map<string, string>;
  setList?: Array<Set>;
  onCreateExercise?: (exercise: Exercise) => void;
}

export const CreateExercise = (props: CreateExerciseProps) => {
  const [exercise, setExercise] = useState<Exercise>({ category: 0, templateConfig: {}, description: '', name: '' });
  const [image, setImage] = useState<any>();
  const [video, setVideo] = useState<any>();

  useEffect(() => {
    const loadDefaultImage = async () => {
      setImage(await (await fetchWithReauth(defaultImage)).blob());
    };
    loadDefaultImage();
  }, []);

  const clickButton = async (e: any) => {
    if (exercise.name && exercise.description && image) {
      const imageId = await uploadFile(image);
      let videoId;
      if (video) videoId = await uploadFile(video);
      let newExercise = await createExercise({ ...exercise, imageId: imageId, videoId: videoId });
      console.log(newExercise);
      setExercise({ category: 0, templateConfig: {}, description: '', name: '', setId: '' });
      setImage(await (await fetchWithReauth(defaultImage)).blob());
      if (props.onCreateExercise) props.onCreateExercise(exercise);
    } else alert('Error, data missing.');
  };
  return (
    <div className="exercise-wrapper exercise-new">
      <EditExercise
        setList={props.setList}
        exercise={exercise}
        setExercise={setExercise}
        image={image}
        setImage={setImage}
        video={video}
        setVideo={setVideo}
        fileCache={props.fileCache}
      />
      <div className="exercise-wrapper-buttons">
        <button onClick={(e) => clickButton(e)}>Create</button>
      </div>
    </div>
  );
};
