import { useState } from 'react';
import { Category, Exercise } from '../models/exercise';
import { Set } from '../models/set';
import { ImageFromFile } from './ImageFromFile';
import { ImageFromServer } from './ImageFromServer';
import { SetDropdown } from './SetDropdown';
import { VideoFromFile } from './VideoFromFile';
import { VideoFromServer } from './VideoFromServer';

interface EditExerciseProps {
  setList?: Array<Set>;
  fileCache: Map<string, string>;
  exercise: Exercise;
  setExercise: (e: Exercise) => void;
  image: any;
  setImage: (image: any) => void;
  video: any;
  setVideo: (video: any) => void;
}

export const EditExercise = (props: EditExerciseProps) => {
  const [selectedSet, setSelectedSet] = useState<Set>(props.setList?.find((s: Set) => s.id === props.exercise.setId) || { name: '', id: '', creatorId: '' });

  const enumToArray = () => Object.keys(Category).filter((key) => isNaN(Number(key)));
  return (
    <div className="exercise">
      <div className="exercise-title">
        Title:<input value={props.exercise.name} onChange={(e: any) => props.setExercise({ ...props.exercise, name: e.target.value })} type="text"></input>
      </div>
      <div className="exercise-image-wrapper">
        {props.image ? <ImageFromFile image={props.image} /> : <ImageFromServer fileCache={props.fileCache} alt="" className="exercise-image" id={props.exercise.imageId} />}
        <input className="exercise-image-file-input" type="file" onChange={(e) => props.setImage(e.target.files ? e.target.files[0] : null)} />
      </div>
      <div className="exercise-video-wrapper">
        {props.video ? <VideoFromFile video={props.video} /> : <VideoFromServer fileCache={props.fileCache} alt="" className="exercise-video" id={props.exercise.videoId} />}
        <input className="exercise-video-file-input" type="file" onChange={(e) => props.setVideo(e.target.files ? e.target.files[0] : null)} />
      </div>
      <div>
        <SetDropdown
          setList={props.setList}
          setChanged={(s: Set) => {
            setSelectedSet(s);
            props.setExercise({ ...props.exercise, setId: s?.id });
          }}
          selectedSet={selectedSet}
        ></SetDropdown>{' '}
        <br />
      </div>
      {/* <div className='exercise-difficulty'><input type='number' value={props.exercise.difficulty} onChange={(e: any) => props.setExercise({...props.exercise, difficulty: e.target.value })}></input>    </div>  */}
      <div className="exercise-category">
        <select value={Category[props.exercise.category]} onChange={(e: any) => props.setExercise({ ...props.exercise, category: Category[e.target.value] as any })}>
          {enumToArray().map((v) => (
            <option value={v}>{v}</option>
          ))}
        </select>
      </div>
      <div className="exercise-description">
        <textarea
          style={{ minWidth: '350px', minHeight: '100px' }}
          onChange={(e: any) => {
            props.setExercise({ ...props.exercise, description: e.target.value });
          }}
          value={props.exercise.description}
        ></textarea>
      </div>
    </div>
  );
};
