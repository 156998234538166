import {Set} from '../models/set';

export const SetDropdown = (props: any) => {
    return <select value={props.selectedSet?.id}  onChange={(e) => {
      if(props.onChange)
        props.onChange(e);
      const selectedSet = props.setList?.find((s: Set) => e.target.value === s.id);
      if(props.setChanged)
        props.setChanged(selectedSet);
    }}>
      <option value="">*empty/all*</option>
      {props.setList?.map((s : Set) => <option selected={props.selectedSet?.id === s.id} value={s.id?.toString()}>{s.name?.toString()}</option>)}
      </select>
   }