import { CreateUser } from "./../models/user";
import { User } from "../models/user";
import { fetchWithReauth } from "./auth";

export async function getUserList(): Promise<Array<User>> {
  return await (await fetchWithReauth("/api/user")).json();
}

export async function deleteUserById(deleteUserId: string) {
  const deleteResult = await fetchWithReauth(`/api/user/${deleteUserId}`, {
    method: "DELETE",
    headers: { "content-type": "application/json" },
    //body: JSON.stringify(deleteSet),
  });
  return deleteResult.status;
}

export async function createUser(createUser: CreateUser) {
  const createResult = await fetchWithReauth(`/api/user/`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(createUser),
  });
  return await createResult.json();
}
