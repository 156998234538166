import { useState } from "react";
import { login } from "../api/auth";

export function Login(props: any){
    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();

return (
    <div className="App">
      <div className='top-bar'>
        <div className='pad' style={{padding:'3px'}}><b>Login</b></div>
      <div className='pad'></div>
      <div className='filter'>
      Username:
      </div>
      <div className='filter'>
    <input className='username-input' type='text' onChange={e => {setUsername(e.target.value)}} />
    </div>
    <div className=''>
    Password
    </div>
    <div className=''>
    <input className='password-input' type='password' onChange={e => {setPassword(e.target.value)}} />
    </div>
    <div>
    <button onClick={async ()=> {
        props.onLogin(await login({username:username ?? "", password:password ?? ""}));
    }}>Login</button>
    </div>
      </div>
     
  
      
    </div>
  );
}
