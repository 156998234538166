import { useParams } from "react-router-dom";

export function VerificationComplete(props: any){
    let {result} = useParams<any>();
    return (
        <div className="App">
          <div className='top-bar'>
            <div className='pad' style={{padding:'3px'}}><b>{result === "true" ? "Verification Complete" : "Verification Failed"}</b></div>
          <div className='pad'></div>
        <div>
        </div>
          </div>
        </div>
      );
}