import { useEffect, useState } from "react";
import { createUser, deleteUserById, getUserList } from "../api/user";
import { CreateUser, Role, User } from "../models/user";
import {Set} from "../models/set";
import { getSetList } from "../api/exercise";


export function Users(){
  const [userList, setUserList] = useState<Array<User>>();
  const [setList, setSetList] = useState<Array<Set>>();
  const [newUser, setNewUser] = useState<CreateUser>({password:"", roles: [Role.User], username:"", active: true});
  
  useEffect( () => {
    async function initialize(){
      if(!userList){
        const l = await getUserList();
        setUserList(l);
      }
      if(!setList)
      setSetList(await getSetList());
    }
    initialize();
   }, [setList, userList]);

   return <div className="App">
   <div className='top-bar'>
     <div className='pad' style={{padding:'3px'}}><b>User Manager</b></div>
   <EditUser user={newUser} setUser={setNewUser} />
   <button onClick={async ()=> {
     const newServerUser = await createUser(newUser);
      setUserList([...userList ?? [], newServerUser])
  }}>Create User</button>
   <div className='pad'></div>
   </div>

   {userList?.map((u : User) => <div>
   <div>
     {u.username}
   </div>
   <div>
     Roles:{u.roles.map((r,i) => r + ( i !== u.roles.length - 1 ? ", " : ""))} 
   </div>
   <div>
    Active: {String(u.active)}
    </div>
   <div>
     Sets: 
     {u.setIds.map((sid, i) => setList?.find(s =>  sid === s.id)?.name + (i !== u.setIds.length -1 ? ", " : ""))} 
   </div>
   <button onClick={async() => {
      // eslint-disable-next-line no-restricted-globals
      const deleteConfirm = confirm("Really delete user?");
      if(deleteConfirm){
       const status =  await deleteUserById(u.userId);
       if(status === 200)
       setUserList(userList.filter(ul => ul.userId !== u.userId));
      else
        alert("Could not delete user. <add response>")
      }
      }}>Delete user</button>
    </div>
  
  )}
 </div>
}

function EditUser(props: any){
  return(
    <div style={{backgroundColor:'darkslategrey'}}>
     <span className='user-name'>Name:<input value={props.user.username} onChange={(e : any) => props.setUser({...props.user, username: e.target.value})} type='text'></input></span>
     <span>
     </span>
     <span className='user-password'>Password:<input type='password' value={props.user.password} onChange={(e: any) => props.setUser({...props.user, password: e.target.value })}></input>    </span> 
     <span className='user-role'>
       Primary Role:
       <select value={props.user.roles[0]} onChange={(e) => props.setUser({...props.user, roles:[e.target.value] })}>
       <option value="admin">Admin</option>
       <option value="uploader">Uploader</option>
       <option value="user">User</option>
     </select></span>
    </div>)
}