import { useEffect, useState } from "react";
import { getFile } from "../api/file";
import { FileResponse } from "../models/fileResponse";

export function ImageFromServer(props : any){
  const [imageBase64, setImageBase64] = useState<string>();
  const [imageNotFound, setImageNotFound] = useState<boolean>(false);
  let r = new FileReader();
  useEffect(() => {
    const load = async () => {
      if(!props.id){
        console.warn("File not defined.")
        return;
      }
        const response = await getFile(props.id);
        if(response.blob && typeof(response.blob) === "object")
          r.readAsDataURL((response as FileResponse).blob);
        else
          setImageNotFound(true);

    }
    if(!props?.fileCache?.has(props.id))
      load();
    else
      setImageBase64(props.fileCache.get(props.id))
  },[]);
 
  
  r.onloadend = (e) => {
    const result = r.result?.toString();
    if(result){
      props?.fileCache?.set(props.id, result);
      setImageBase64(result);
    }
  };

  if(imageNotFound)
    return (<div className='exercise-image'>Image not found</div>)

  if(imageBase64)
    return (<img alt='' className='exercise-image' src={imageBase64} />)
  return (<div className='exercise-image'>No Image</div>)
}