import { useEffect, useState } from "react";
import { getFile } from "../api/file";
import { FileResponse } from "../models/fileResponse";

export function VideoFromServer(props : any){
  const [videoBase64, setVideoBase64] = useState<string>();
  const [videoNotFound, setVideoNotFound] = useState<boolean>();
  let r = new FileReader();
  useEffect(() => {
    const load = async () => {
      if(!props.id) return;
      const response = await getFile(props.id);
      if(response.blob && typeof(response.blob) === "object")r.readAsDataURL((response as FileResponse).blob);
      else setVideoNotFound(true);
    }
    if(!props?.fileCache?.has(props.id))
      load();
    else
      setVideoBase64(props.fileCache.get(props.id))
  },[]);
 
  
  r.onloadend = (e) => {
    const result = r.result?.toString();
    if(result){
      props?.fileCache?.set(props.id, result);
      setVideoBase64(result);
    }
  };

  if(videoNotFound)return (<div className='exercise-image'>Video not found</div>)

  if(videoBase64)
    return (<video controls className='exercise-image' src={videoBase64}></video>)
  return (<div className='exercise-image'>No Video</div>)
}