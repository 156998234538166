import { useEffect, useState } from 'react';
import { getFile, uploadFile } from '../api/file';
import { getThemeByName, getThemeNameList, updateTheme } from '../api/theme';
import { FileResponse } from '../models/fileResponse';
import { SoundType } from '../models/soundType';
import { SoundMap, Theme } from '../models/theme';

const enumToArray = () => Object.keys(SoundType).filter((key) => isNaN(Number(key)));

export function Themes() {
  const [themeList, setThemeList] = useState<Array<string>>();
  const [themeName, setThemeName] = useState<string>('');
  const [renderTheme, setRenderTheme] = useState<Theme>();

  useEffect(() => {
    async function initialize() {
      if (!themeList) {
        const l = await getThemeNameList();
        setThemeList(l);
      }
      if (themeName !== '') {
        console.log('Getting theme from server');
        const t = await getThemeByName(themeName);
        setRenderTheme(t);
        setThemeName('');
      }
    }
    initialize();
  }, [themeList, themeName]);

  return (
    <div className="App">
      <h2>Themes</h2>
      <div className="top-bar"></div>
      {themeList?.map(RenderThemeName)}
      <div>&nbsp;</div>
      {renderTheme ? RenderTheme() : null}
    </div>
  );

  function RenderThemeName(themeName: string) {
    return (
      <div
        onClick={() => {
          setThemeName(themeName);
        }}
      >
        {themeName}
      </div>
    );
  }

  function RenderTheme() {
    interface SoundMapProps {
      soundIndex: number;
      soundMap?: SoundMap;
    }

    const SoundSelector = (props: SoundMapProps) => {
      const [soundFile, setSoundFile] = useState<File | null>();
      const [soundFileUri, setSoundFileUri] = useState<string>('');

      let r = new FileReader();
      useEffect(() => {
        const load = async () => {
          if (props.soundMap?.id) {
            const response = await getFile(props.soundMap.soundId);
            if (response.blob && typeof response.blob === 'object') {
              r.readAsDataURL((response as FileResponse).blob);
            }
          }
        };
        load();
      }, []);

      r.onloadend = (e) => {
        const result = r.result?.toString();
        if (result) {
          setSoundFileUri(result);
        }
      };

      return (
        <div>
          <div style={{ display: 'inline-block' }}>ID: {props.soundIndex}</div>
          <input
            className="exercise-image-file-input"
            type="file"
            onChange={(e) => {
              let f = e.target.files ? e.target.files[0] : '';
              if (e.target.files) {
                setSoundFile(e.target.files[0]);
              }
            }}
          />

          {soundFile !== undefined && soundFile !== null ? (
            <div style={{ background: 'blue' }}>
              {SoundType[props.soundIndex]}
              <button
                onClick={async () => {
                  const fileId: string = await uploadFile(soundFile);
                  let soundMap: SoundMap = { id: props.soundIndex, soundId: fileId };
                  if (renderTheme) {
                    renderTheme.soundMap[props.soundIndex] = soundMap;
                    setRenderTheme(renderTheme);
                  }
                }}
              >
                Upload
              </button>
              <audio src={URL.createObjectURL(soundFile)} controls></audio>
            </div>
          ) : (
            <div style={{ background: 'red' }}>
              {SoundType[props.soundIndex]}
              <audio src={soundFileUri} controls></audio>
            </div>
          )}
        </div>
      );
    };

    const RenderSoundSelectors = (theme: Theme) => {
      return (
        <>
          <div>
            <h4>Sounds:</h4>
            {enumToArray().map((s, i) => (
              <SoundSelector soundIndex={i} soundMap={theme.soundMap[i]} />
            ))}
          </div>
        </>
      );
    };
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div>
            <div>Theme Name: {renderTheme!.name}</div>
            <div>&nbsp;</div>
            <button
              onClick={async () => {
                let t = renderTheme;
                updateTheme(t!);
              }}
            >
              Save
            </button>
          </div>
          {RenderCss(renderTheme!)}
          {RenderSoundSelectors(renderTheme!)}
        </div>
      </>
    );
    function RenderCss(theme: Theme) {
      return (
        <>
          <div>
            {RenderColorSelector('Background', theme.css.background, (color) => setRenderTheme({ ...renderTheme!, css: { ...renderTheme!.css, background: color } }))}
            {RenderColorSelector('ActiveTintColor', theme.css.activeTintColor, (color) =>
              setRenderTheme({ ...renderTheme!, css: { ...renderTheme!.css, activeTintColor: color } }),
            )}
            {RenderColorSelector('HeaderColor', theme.css.headerColor, (color) => setRenderTheme({ ...renderTheme!, css: { ...renderTheme!.css, headerColor: color } }))}
            {RenderColorSelector('HeaderTintColor', theme.css.headerTintColor, (color) =>
              setRenderTheme({ ...renderTheme!, css: { ...renderTheme!.css, headerTintColor: color } }),
            )}
            {RenderColorSelector('TabIconDefault', theme.css.tabIconDefault, (color) => setRenderTheme({ ...renderTheme!, css: { ...renderTheme!.css, tabIconDefault: color } }))}
            {RenderColorSelector('TabIconSelected', theme.css.tabIconSelected, (color) =>
              setRenderTheme({ ...renderTheme!, css: { ...renderTheme!.css, tabIconSelected: color } }),
            )}
            {RenderColorSelector('Tint', theme.css.tint, (color) => setRenderTheme({ ...renderTheme!, css: { ...renderTheme!.css, tint: color } }))}
            {RenderColorSelector('Text', theme.css.text, (color) => setRenderTheme({ ...renderTheme!, css: { ...renderTheme!.css, text: color } }))}

            {RenderColorSelector('LargeInfoTextColor', theme.css.largeInfoTextColor, (color) =>
              setRenderTheme({ ...renderTheme!, css: { ...renderTheme!.css, largeInfoTextColor: color } }),
            )}
          </div>
        </>
      );
    }
  }
}

function RenderColorSelector(title: string, color: string, setColor: (s: string) => void) {
  return (
    <div style={{ width: '200px', textAlign: 'right' }}>
      {title}: <input type="color" id={color} value={color} onChange={(e) => setColor(e.target.value)}></input>
    </div>
  );
}
