import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { Exercises } from './pages/Exercises';
import { Themes } from './pages/Themes';
import { Login } from './pages/Login';
import { Users } from './pages/Users';
import { Sets } from './pages/Sets';
import { VerificationComplete } from './pages/public/VerificationComplete';
import { Datenschutz } from './pages/public/Datenschutz';
import { Export } from './pages/Export';

function App() {
  const isLoggedIn = (() => {
    const stickyValue = window.localStorage.getItem('isLoggedIn');
    return stickyValue !== null ? JSON.parse(stickyValue) : false;
  })();
  return (
    <Router>
      <Switch>
        <Route path="/user/verify/:result" children={<VerificationComplete />} />
        <Route path="/login">
          <Login
            onLogin={(r: boolean) => {
              window.localStorage.setItem('isLoggedIn', JSON.stringify(r));
              window.location.href = '/';
            }}
          />
        </Route>
        <Route path="/datenschutz">
          <Datenschutz />
        </Route>
        <Route path="/exercises">
          {!isLoggedIn ? (
            <Login
              onLogin={(r: boolean) => {
                window.localStorage.setItem('isLoggedIn', JSON.stringify(r));
                window.location.href = '/exercises';
              }}
            />
          ) : (
            <Exercises />
          )}
        </Route>
        <Route path="/themes">
          {!isLoggedIn ? (
            <Login
              onLogin={(r: boolean) => {
                window.localStorage.setItem('isLoggedIn', JSON.stringify(r));
                window.location.href = '/themes';
              }}
            />
          ) : (
            <Themes />
          )}
        </Route>
        <Route path="/users">
          {!isLoggedIn ? (
            <Login
              onLogin={(r: boolean) => {
                window.localStorage.setItem('isLoggedIn', JSON.stringify(r));
                window.location.href = '/users';
              }}
            />
          ) : (
            <Users />
          )}
        </Route>
        <Route path="/sets">
          {!isLoggedIn ? (
            <Login
              onLogin={(r: boolean) => {
                window.localStorage.setItem('isLoggedIn', JSON.stringify(r));
                window.location.href = '/sets';
              }}
            />
          ) : (
            <Sets />
          )}
        </Route>
        <Route path="/export">
          {!isLoggedIn ? (
            <Login
              onLogin={(r: boolean) => {
                window.localStorage.setItem('isLoggedIn', JSON.stringify(r));
                window.location.href = '/export';
              }}
            />
          ) : (
            <Export />
          )}
        </Route>
        <Route path="/">
          {!isLoggedIn ? (
            <Login
              onLogin={(r: boolean) => {
                window.localStorage.setItem('isLoggedIn', JSON.stringify(r));
                window.location.href = '/';
              }}
            />
          ) : (
            renderMenu()
          )}
        </Route>
      </Switch>
    </Router>
  );
}

function renderMenu() {
  return (
    <div>
      <nav>
        <ul>
          <li>
            <Link to="/sets">Sets</Link>
          </li>
          <li>
            <Link to="/users">Users</Link>
          </li>
          <li>
            <Link to="/exercises">Exercises</Link>
          </li>
          <li>
            <Link to="/themes">Themes</Link>
          </li>
          <li>
            <Link to="/export">Export</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default App;
