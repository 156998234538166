import { DeleteSet, SetAsDefaultSet } from './../models/set';
import { fetchWithReauth } from './auth';
import { AssignSet, Set } from '../models/set';

export async function getSetList(): Promise<Array<Set>> {
  return await (await fetchWithReauth('/api/exercise')).json();
}

export async function updateSet(set: Set) {
  let newSet = await fetchWithReauth('/api/set/', {
    method: 'PUT',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(set),
  });
  return newSet.json();
}

export async function createSet(set: Set) {
  let newSet = await fetchWithReauth('/api/set/', {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(set),
  });
  return newSet.json();
}

export async function assignSet(assignSet: AssignSet) {
  await fetchWithReauth('/api/set/assign', {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(assignSet),
  });
}

export async function unAssignSet(assignSet: AssignSet) {
  await fetchWithReauth('/api/set/unassign', {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(assignSet),
  });
}

export async function setAsDefault(setAsDefaultSet: SetAsDefaultSet) {
  await fetchWithReauth('/api/set/setDefaultSet', {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(setAsDefaultSet),
  });
}

export async function deleteSet(deleteSet: DeleteSet) {
  await fetchWithReauth('/api/set/', {
    method: 'DELETE',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(deleteSet),
  });
}
