import { Theme } from '../models/theme';
import { fetchWithReauth } from './auth';

export async function getThemeNameList(): Promise<Array<string>> {
  return await (await fetchWithReauth('/api/theme')).json();
}

export async function getThemeList(): Promise<Array<Theme>> {
  return await await (await fetchWithReauth('/api/theme/list')).json();
}

export async function getThemeByName(name: string): Promise<Theme> {
  return await (await fetchWithReauth(`/api/theme/${name}`)).json();
}

export async function updateTheme(theme: Theme): Promise<Theme> {
  return await (
    await fetchWithReauth('/api/theme/update', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(theme),
    })
  ).json();
}
