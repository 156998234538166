import { useEffect, useState } from "react";
import { getExerciseList, getSetList } from "../api/exercise";
import { Exercise } from "../models/exercise";
import './Exercises.css';
import {Set } from "../models/set";
import { SetDropdown } from "../components/SetDropdown";
import { EditableExercise } from "../components/EditableExercise";
import { CreateExercise } from "../components/CreateExercise";

const fileCache = new Map<string,string>();

export function Exercises(){
  const [exerciseList, setExerciseList] = useState<Array<Exercise>>();
  const [filteredExerciseList, setFilteredExerciseList] = useState<Array<Exercise>>();
  
  const [setList, setSetList] = useState<Array<Set>>();
  const [filter,setFilter] = useState<Set|undefined>();
  const [selectedSet, setSelectedSet] = useState<Set>();
  
  const createFilteredExerciseList = (exerciseList : Array<Exercise>) => {
    return exerciseList?.filter(e => !filter  ? true : e.setId === filter?.id);
  }

  useEffect( () => {
    async function initialize(){
      if(!exerciseList){
        const l = await getExerciseList();
        setExerciseList(l);
      }
      if(filter && exerciseList ){
          const fl = createFilteredExerciseList(exerciseList);
          setFilteredExerciseList(fl);
      }else if(!filter){
        setFilteredExerciseList(exerciseList);
      }
    
      if(!setList)
        setSetList(await getSetList());
    }
    initialize();
   }, [ exerciseList, setList,filter]);

  return (
    <div className="App">
      <div className='top-bar'>
        <div className='pad' style={{padding:'3px'}}><b>Exercise Manager</b></div>
      <CreateExercise setList={setList} fileCache={fileCache} onCreateExercise={(e) => {  setExerciseList([...(exerciseList??[]), e])}} />
      <div className='pad'></div>
      <div className='filter'>
        <span>Set Filter</span>
         <SetDropdown setList={setList} selectedSet={filter} setChanged={(set: Set) => { setFilter(set)}}></SetDropdown>
         <span>Set Select</span>
         <SetDropdown setList={setList} setChanged={(s: Set) => {setSelectedSet(s)}} selectedSet={selectedSet}></SetDropdown>
      </div>
      </div>
  
      {filteredExerciseList?.map((e : any) => (<EditableExercise key={e.id} 
          exercise={e} setList={setList} fileCache={fileCache}
          selectedSet={selectedSet}
          onCopyExercise={async () => {setExerciseList(await getExerciseList());}}
          onDeleteExercise={async (id) => {
            const filtered = exerciseList?.filter(e => e.id !== id)|| [];
            setExerciseList(filtered);
          }}/>))
          }
    </div>
  );
}


  
