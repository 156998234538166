import { useEffect, useState } from "react";
import { getSetList } from "../api/exercise";
import { assignSet, createSet, deleteSet, setAsDefault, unAssignSet, updateSet } from "../api/set";
import { getUserList } from "../api/user";
import {Set} from '../models/set';
import { User } from "../models/user";

export function Sets(){
    const [setList, setSetList] = useState<Array<Set>>();
    const [userList, setUserList] = useState<Array<User>>();
    const [createSetName, setCreateSetName] = useState<string>("");

    useEffect( () => {
      async function initialize(){
        if(!setList){
          const sl = await getSetList();
          setSetList(sl);
        }
        if(!userList){
        const ul = await getUserList();
        setUserList(ul);
        }
      }
      initialize();
     }, [setList, userList]);    

    return <div className="App">
      
        
   <div className='top-bar'>
     <div className='pad' style={{padding:'3px'}}><b>Set Manager</b></div>
   <div className='pad'></div>
   </div>
   <div style={{backgroundColor:'darkslategrey'}}>Name:<input value={createSetName} onChange={(e : any) => setCreateSetName(e.target.value)} type='text'></input>
        <button onClick={async ()=> {
           const set = await createSet( {name:createSetName, useTimerForAllExercises: false})
            setSetList([...(setList??[]), set]);
        }}>Create Set</button>
        </div>
        <div className='pad'></div>
   {setList?.map((s : Set) => <SetRow  setUserList={setUserList} userList={userList|| []} set={s} setList={setList} setSetList={(s: Array<Set>)=> { setSetList(s)}}></SetRow>)}
 </div>
}

interface SetRowProps{
  userList: Array<User>;
  setUserList:(setList: Array<User>) => void
  setList: Array<Set>;
  set:Set;
  setSetList:(setList: Array<Set>) => void
}

function SetRow(props: SetRowProps){
    const [selectedUser, setSelectedUser] = useState<User>();
    const [unSelectedUser, setUnSelectedUser] = useState<User>();
    return <>
    <div>  Name:{props.set?.name}   Assigned Users: {props.userList?.filter(u => u.setIds.some(s => s === props.set.id)).map(u => u.username + " ")}
    Creator/Owner: {props.userList.find(u => u.userId === props.set.creatorId)?.username}
    </div>
    Timer
    <input type='checkbox' onChange={async () => {
      props.set.useTimerForAllExercises = !props.set.useTimerForAllExercises;
      props.setSetList([...props.setList]);
      await updateSet(props.set);
      }} checked={props.set?.useTimerForAllExercises ?? false}></input>
    <UserDropdown userList={props.userList?.filter((u:User)=> !u.setIds?.includes(props.set.id || ""))} setId={props.set?.id} userChanged={(u:User)=> {setSelectedUser(u)}} ></UserDropdown>
    {props.set?.id ? <>
    <button onClick={async() => {
      await assignSet({setId:props.set?.id || "", userId:selectedUser?.userId || ""});
      //TODO: Probably inefficient
      selectedUser?.setIds.push(props.set.id || "");
      props.setUserList(props.userList.map(u => u.userId !== selectedUser?.userId ? u : selectedUser));
      }}>Assign</button>

       <UserDropdown userList={props.userList?.filter((u:User)=> u.setIds?.includes(props.set.id || ""))} setId={props.set?.id} userChanged={(u:User)=> {setUnSelectedUser(u)}} ></UserDropdown>
       <button onClick={async() => {
      await unAssignSet({setId:props.set?.id || "", userId:unSelectedUser?.userId || ""});
      
      if(unSelectedUser){
        unSelectedUser.setIds = unSelectedUser?.setIds.filter(id => props.set.id !== id);
        props.setUserList(props.userList.map(u => u.userId === unSelectedUser?.userId ?  unSelectedUser : u));
      }

      }}>Unassign</button>
   
    <button onClick={async () => {
      if(props.set.id){
        await setAsDefault({setId: props.set.id});
        alert('OK');
      }
    }}>
      Set as Default
    </button>
    <button onClick={async() => {
      // eslint-disable-next-line no-restricted-globals
      const deleteConfirm = confirm("Really delete set and all exercises?");
      if(deleteConfirm)
        await deleteSet({setId:props.set.id || "",deleteExercises:true});
        props.setSetList(props.setList.filter((s:Set) => s.id !== props.set.id));
      }}>Delete set and exercises</button>
       <button onClick={async() => {
      // eslint-disable-next-line no-restricted-globals
      const deleteConfirm = confirm("Really delete set (without exercises)?");
      if(deleteConfirm)
        await deleteSet({setId:props.set.id || "",deleteExercises:false});
        props.setSetList(props.setList.filter((s:Set) => s.id !== props.set.id));
      }}>Delete</button>
    </>: null}</>
}

function UserDropdown(props: any){
    //props.userList?.forEach((u:User)=> console.log(!u.setIds?.includes(props.setId)));
    
    return <select value={props.selectedUser?.id}  onChange={(e) => {
      if(props.onChange)
        props.onChange(e);
      const selectedUser = props.userList?.find((u: User) => e.target.value === u.userId);
      if(props.userChanged)
        props.userChanged(selectedUser);
    }}>
         <option value="">*empty/all*</option>
      {props.userList?.map((u : User) => <option selected={props.selectedUser?.id === u.userId} value={u.userId?.toString()}>{u.username?.toString()}</option>)}
      </select>
   }