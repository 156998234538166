import { CopyExerciseDTO, Exercise } from './../models/exercise';
import { fetchWithReauth } from './auth';
import { Set } from '../models/set';

export async function createExercise(exercise: Exercise) {
  let newExercise = await fetchWithReauth('/api/exercise/create', {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(exercise),
  });
  return newExercise.json();
}

export async function updateExercise(exercise: Exercise) {
  await fetchWithReauth(`/api/exercise/update`, {
    method: 'PUT',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(exercise),
  });
}

export async function copyExercise(copyDTO: CopyExerciseDTO) {
  await fetchWithReauth(`/api/exercise/copy`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(copyDTO),
  });
}

export async function getExerciseList(): Promise<Array<Exercise>> {
  return await (await fetchWithReauth('/api/exercise')).json();
}

export async function getSetList(forceRelogin = false): Promise<Array<Set>> {
  const setList = await fetchWithReauth('/api/set/all', {}, forceRelogin);
  return setList.ok ? await setList.json() : undefined;
}

export async function deleteExercise(id: string): Promise<any> {
  return await fetchWithReauth(`/api/exercise/${id}`, { method: 'DELETE' });
}
