import { LoginUser } from '../models/user';

export async function login(user: LoginUser): Promise<boolean> {
  const resp = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/user/auth/login`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
    method: 'POST',
  });
  if (resp.ok) {
    console.log('User successfully logged in');
    localStorage.setItem('user', JSON.stringify(user));
    const token = (await resp.json()).access_token;
    localStorage.setItem('token', JSON.stringify(token));
    return true;
  } else {
    console.warn('Login failed');
    return false;
  }
}

//TODO: This is problematic if used in parallel, Promise.all etc.
//Better to update session jwt in this case. (separate method)
export async function fetchWithReauth(input: RequestInfo, init?: RequestInit | undefined, force?: boolean) {
  const userString = localStorage.getItem('user');
  const localUser = JSON.parse(userString ?? '');
  let result = await fetch(input, {
    ...init,
    headers: { ...init?.headers, ...{ Authorization: `Bearer ${getToken()}` } },
  });
  if ((result.status === 401 && localUser) || force) {
    console.log('Session is probably expired. Trying to login with stored credentials.');
    await login(localUser);
    return await fetch(input, {
      ...init,
      headers: {
        ...init?.headers,
        ...{ Authorization: `Bearer ${getToken()}` },
      },
    });
  } else return result;
}

const getToken = () => {
  return JSON.parse(localStorage.getItem('token') || '');
};
