import { useState } from "react";
import { copyExercise, deleteExercise, updateExercise } from "../api/exercise";
import { uploadFile } from "../api/file";
import {  Exercise as ExerciseModel } from "../models/exercise";
import {Set} from "../models/set";
import { EditExercise } from "./EditExercise";
import { Exercise } from "./Exercise";
import { SetDropdown } from "./SetDropdown";


interface EditableExerciseProps {
    exercise : ExerciseModel;
    fileCache: Map<string,string>;
    selectedSet?: Set;
    setList:Array<Set>| undefined;
    onDeleteExercise?: (id: string) => void;
    onCopyExercise?:() => void;
}

  export const EditableExercise = (props : EditableExerciseProps) => {
    const [editMode, setEditMode] = useState<boolean>(false);
    

    const [exerciseEl, setExercise] = useState<ExerciseModel>(props.exercise);
    const [video, setVideo] = useState<File| null>();
    const [image, setImage] = useState<File| null>();

    if(!editMode)
      return (
        <div className='exercise-wrapper'>
        <Exercise exercise={exerciseEl} fileCache={props.fileCache} setList={props.setList} />
        <div className='exercise-wrapper-buttons'>
        <div><button onClick={async ()=> { await copyExercise({exerciseId: exerciseEl.id || "", targetSetId: props.selectedSet?.id || ""});
          if(props.onCopyExercise)props.onCopyExercise();
        }}>Copy To</button> 
         <br/></div>  
        <button onClick={()=> setEditMode(true)}>Edit</button>
        <button onClick={async ()=> {
          await deleteExercise(exerciseEl.id || "");
          if(props.onDeleteExercise && exerciseEl.id)props.onDeleteExercise(exerciseEl.id);
        
      }}>Delete</button>
        </div>
        </div>
        )
    else
      return (<div className='exercise-wrapper'>
        <EditExercise exercise={exerciseEl} setExercise={(setExercise)} image={image} setImage={setImage} video={video} setVideo={setVideo} fileCache={props.fileCache} />
        <div className='exercise-wrapper-buttons'><button onClick={()=> setEditMode(false)}>Cancel</button>
             <button onClick={async () => {
        if(image){
          const fileId = await uploadFile(image);
          exerciseEl.imageId = fileId;
        }
        if(video){
          const fileId = await uploadFile(video);
          exerciseEl.videoId = fileId;
        }
        await updateExercise(exerciseEl); 
        setEditMode(false)}}>Save</button></div>
      </div>)
  
}
