export interface Exercise {
  id?: string;
  name: string;
  setId?: string;
  description: string;
  templateConfig: TemplateConfig;
  imageId?: string;
  videoId?: string;
  category: Category;
}

export interface TemplateConfig {
  weight?: number;
  time?: number;
  repetitions?: number;
  useTimer?: boolean;
}

export enum Category {
  legs,
  arms,
  core,
  cardio,
  chest,
  back,
  stretching,
}

export interface CopyExerciseDTO {
  exerciseId: string;
  targetSetId: string;
}
