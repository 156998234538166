import { Category, Exercise as ExerciseModel } from '../models/exercise';
import { Set } from '../models/set';
import { ImageFromServer } from './ImageFromServer';
import { VideoFromServer } from './VideoFromServer';

interface ExerciseProps {
  exercise: ExerciseModel;
  setList: Array<Set> | undefined;
  fileCache: Map<string, string>;
}

export const Exercise = (props: ExerciseProps) => {
  return (
    <div className="exercise">
      <div className="exercise-title">{props.exercise.name}</div>
      <div className="exercise-image-wrapper">
        <ImageFromServer fileCache={props.fileCache} alt="" className="exercise-image" id={props.exercise.imageId} />
      </div>
      <div className="exercise-video-wrapper">
        <VideoFromServer fileCache={props.fileCache} className="exercise-video" id={props.exercise.videoId}></VideoFromServer>
      </div>
      <p>
        Weight:{props.exercise.templateConfig.weight}
        <div style={{ marginTop: 4 }} />
        Reps: {props.exercise.templateConfig.repetitions}
        <div style={{ marginTop: 4 }} />
        Time: {props.exercise.templateConfig.time}
        <div style={{ marginTop: 4 }} />
        {props.exercise.templateConfig.useTimer ? 'Use Timer' : undefined}
      </p>

      <div className="exercise-category">{Category[props.exercise.category]}</div>
      {props.exercise.setId ? <div className="exercise-group">{props.setList?.find((s) => s.id === props.exercise.setId)?.name}</div> : null}
      <div className="exercise-description">{props.exercise.description}</div>
    </div>
  );
};
