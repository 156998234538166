import { useEffect, useState } from "react";
import { getSetList } from "../api/exercise";
import { downloadExport } from "../api/export";
import { getThemeList } from "../api/theme";
import {Set} from '../models/set';
import { Theme } from "../models/theme";

export function Export(){
    const [setList, setSetList] = useState<Array<Set>>();
    const [themeList, setThemeList] = useState<Array<Theme>>();
    const [selectedSet, setSelectedSet] = useState<Set>();
    const [selectedTheme, setSelectedTheme] = useState<Theme>();

    useEffect( () => {
      async function initialize(){
        if(!setList){
          const sl = await getSetList();
          setSetList(sl);
          setSelectedSet(sl[0]);
        }
        if(!themeList){
        const tl = await getThemeList();
        setThemeList(tl);
        setSelectedTheme(tl[0]);
        }
      }
      initialize();
     }, [setList, themeList]);    

    return <div className="App">
      
        
   <div className='top-bar'>
     <div className='pad' style={{padding:'3px'}}><b>Exporter</b></div>
   <div className='pad'></div>
   </div>
   <div style={{backgroundColor:'darkslategrey'}}>
       Set: <SetDropDown setList={setList} selectedSet={selectedSet} setChanged={(s : Set) => setSelectedSet(s)} />
       Theme: <ThemeDropDown themeList={themeList} selectedTheme={selectedTheme} themeChanged={(t : Theme) => setSelectedTheme(t)} />
        <button onClick={async ()=> {
            const zip = await downloadExport(selectedSet?.id || "", selectedTheme?.id || "");
            const url = window.URL.createObjectURL(zip);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'resources.zip');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }}>Export</button>
        </div>
        <div className='pad'></div>
   {/* {setList?.map((s : Set) => <SetRow setUserList={setUserList} userList={userList|| []} set={s} setList={setList} setSetList={(s: Array<Set>)=> { setSetList(s)}}></SetRow>)} */}
 </div>
}

function ThemeDropDown(props: any){
    return <select value={props.selectedTheme?.id}  onChange={(e) => {
      if(props.onChange)
        props.onChange(e);
      const selectedTheme = props.themeList?.find((t: Theme) => e.target.value === t.id);
      if(props.themeChanged)
        props.themeChanged(selectedTheme);
    }}>
      {props.themeList?.map((t : Theme) => <option selected={props.selectedTheme?.id === t.id} value={t.id}>{t.name}</option>)}
      </select>
   }

function SetDropDown(props: any){
    return <select value={props.selectedSet?.id}  onChange={(e) => {
      if(props.onChange)
        props.onChange(e);
      const selectedSet = props.setList?.find((s: Set) => e.target.value === s.id);
      if(props.setChanged)
        props.setChanged(selectedSet);
    }}>
      {props.setList?.map((s : Set) => <option selected={props.selectedSet?.id === s.id} value={s.id!.toString()}>{s.name?.toString()}</option>)}
      </select>
   }